import log from "src/helpers/log";
import { getCurTime_MS } from "src/shared/helpers/timeHelpers";
import { Timestamp_MS } from "src/shared/types/general";
import { create } from "zustand";
import { IndividualModalConfig } from "../types";

interface ModalState {
	openLookup: Record<
		string,
		{
			isOpen: boolean;
			openedAt?: Timestamp_MS;
		}
	>;
	propsLookup: Record<string, Record<string, unknown>>;
	prefetchPromiseLookup: Record<string, Promise<unknown>>;
	configLookup: Record<string, IndividualModalConfig<any, any>>;
	open: (
		modal: string,
		options: {
			props: Record<string, unknown>;
		},
	) => void;
	close: (modal: string) => void;
	closeAll: () => void;
	registerModalConfig: (
		modal: string,
		config: IndividualModalConfig<any, any>,
	) => {
		alreadyRegistered: boolean;
	};
	unregisterModalConfig: (modal: string) => void;
	getModalConfig: (modal: string) => IndividualModalConfig<any, any> | undefined;
}

const useModalState = create<ModalState>((set, get) => ({
	openLookup: {},
	propsLookup: {},
	prefetchPromiseLookup: {},
	configLookup: {},
	open: (modal, options) =>
		set((state) => {
			state.openLookup[modal] = { isOpen: true, openedAt: getCurTime_MS() };
			state.propsLookup[modal] = options.props;
			return {
				openLookup: {
					...state.openLookup,
				},
				propsLookup: {
					...state.propsLookup,
				},
			};
		}),
	close: (modal) => {
		set((state) => {
			state.openLookup[modal] = { isOpen: false };

			return {
				openLookup: {
					...state.openLookup,
				},
			};
		});
	},
	closeAll: () =>
		set((state) => {
			return {
				openLookup: {},
			};
		}),
	registerModalConfig: (modal, config) => {
		let alreadyRegistered = false;
		set((state) => {
			if (state.configLookup[modal]) {
				// This tends to happen due to nested contexts
				alreadyRegistered = true;
			}
			state.configLookup[modal] = config;

			return {
				configLookup: {
					...state.configLookup,
				},
			};
		});
		return {
			alreadyRegistered,
		};
	},
	unregisterModalConfig: (modal) =>
		set((state) => {
			if (!state.configLookup[modal]) {
				log.warn({ modal }, `Modal not registered.`);
			}
			delete state.configLookup[modal];

			return {
				configLookup: {
					...state.configLookup,
				},
			};
		}),
	getModalConfig: (modal) => {
		const config = get().configLookup[modal];
		if (!config) {
			log.error({ modal }, `Modal not registered.`);
		}
		return config;
	},
}));

export default useModalState;
