import useHamburgerNav from "src/hooks/useHamburgerNav";
import useNoScroll from "src/hooks/useNoScroll";

/**
 * This is a tracker component that checks to ensure modals have been opened successfully
 *
 * Also handles disabling scroll and removing the hamburger navigation menu
 */
const ModalOpenDataManager = () => {
	useHamburgerNav(false);
	useNoScroll();
	return null;
};
export default ModalOpenDataManager;
