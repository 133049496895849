import { memo, useMemo } from "react";
import { ModalProps, View, ViewProps } from "react-native";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import SafeModal from "src/components/SafeModal";
import useDevice from "src/hooks/useDevice";
import useDim from "src/hooks/useDim";
import useKeyboardListener from "src/hooks/useKeyboardListener";
import { KeyboardListenerCallbackMap } from "src/hooks/useKeyboardListener/types";
import useStyle from "src/hooks/useStyle";
import ModalOpenDataManager from "src/modals/containers/components/ModalOpenDataManager";
import { innerBlurBackgroundColor, outerBlurStyle } from "src/modals/containers/styles";
import { enumNever } from "src/shared/helpers/generalHelpers";
import { Timestamp_MS } from "src/shared/types/general";
import { color } from "src/styles";
import { radiusMedium } from "src/styles/radius";
import { ModalContext, ModalContextType } from "../../../../contexts/ModalContext";
import useModalRegistration from "../../../../hooks/useModalRegistration";
import useModalState from "../../../../hooks/useModalState";
import { IndividualModalConfig } from "../../../../types";

const RootModalIndividual = ({
	name,
	config,
}: {
	name: string;
	config: IndividualModalConfig<any, any>;
}) => {
	useModalRegistration(name, config);
	const variant = config.variant;

	const { isOpen } = useModalState((state) => state.openLookup[name] ?? { isOpen: false });
	const { theme } = useStyle();
	const { device } = useDevice();
	const dim = useDim();
	const close = useModalState((state) => state.close);

	const keyboardListener = useMemo<KeyboardListenerCallbackMap>(
		() => ({
			Escape: () => {
				if (config.allowClose ?? true) {
					const { close, openLookup } = useModalState.getState();
					const openModals: { name: string; openedAt: Timestamp_MS }[] = [];
					for (const modalName in openLookup) {
						const modal = openLookup[modalName];
						if (modal && modal.isOpen && modal.openedAt) {
							openModals.push({ name: modalName, openedAt: modal.openedAt });
						}
					}
					const orderedModals = openModals.sort((a, b) => b.openedAt - a.openedAt);
					const firstModal = orderedModals[0];
					if (firstModal && firstModal.name === name) {
						close(name);
					}
				}
			},
		}),
		[config.allowClose, name],
	);
	useKeyboardListener(keyboardListener);

	const modalProps = useMemo<ModalProps>(() => {
		switch (variant) {
			case "Short":
			case "Tall":
			case "FullFull":
			case "Full":
			case "Blur":
			case "ShortBlur":
			case "FullBlur":
				return {
					animationType: "fade",
					transparent: true,
					presentationStyle: "overFullScreen",
					style: {
						flex: 1,
						maxWidth: dim.width,
						maxHeight: dim.height,
					},
				};
			default:
				return enumNever(variant);
		}
	}, [dim.height, dim.width, variant]);

	const viewProps = useMemo<ViewProps>(() => {
		switch (variant) {
			case "Short":
				return {
					pointerEvents: "box-none",
					style: {
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
					},
				};
			case "Tall":
				if (device === "mobile") {
					return {
						style: {
							flex: 1,
						},
					};
				}
				return {
					pointerEvents: "box-none",
					style: {
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
					},
				};
			case "Full":
				if (device === "mobile") {
					return {
						style: {
							flex: 1,
						},
					};
				}
				return {
					pointerEvents: "box-none",
					style: {
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
					},
				};
			case "Blur":
				return {
					pointerEvents: "box-none",
					style: [
						{
							flex: 1,
						},
						outerBlurStyle,
					],
				};
			case "ShortBlur":
				return {
					pointerEvents: "box-none",
					style: [
						{
							flex: 1,
							justifyContent: "center",
							alignItems: "center",
						},
						outerBlurStyle,
					],
				};
			case "FullBlur":
				return {
					pointerEvents: "box-none",
					style: [
						outerBlurStyle,
						device === "mobile"
							? {
									flex: 1,
								}
							: {
									flex: 1,
									alignItems: "center",
									justifyContent: "center",
								},
					],
				};
			case "FullFull":
				return {
					pointerEvents: "box-none",
					style: {
						flex: 1,
					},
				};
			default:
				return enumNever(variant);
		}
	}, [variant, device]);

	const nestedViewProps = useMemo<ViewProps>(() => {
		switch (variant) {
			case "Short":
				return {
					style: {
						backgroundColor: color.BackgroundModal[theme],
						overflow: "hidden",
						borderRadius: radiusMedium,
					},
				};
			case "Tall":
				if (device === "mobile") {
					return {
						style: {
							backgroundColor: color.BackgroundModal[theme],
							flex: 1,
						},
					};
				}
				return {
					style: {
						backgroundColor: color.BackgroundModal[theme],
						borderRadius: radiusMedium,
						overflow: "hidden",
						minWidth: 550,
						maxHeight: 0.9 * dim.height,
					},
				};
			case "Full":
				if (device === "mobile") {
					return {
						style: {
							backgroundColor: color.BackgroundModal[theme],
							flex: 1,
						},
					};
				}
				return {
					style: {
						backgroundColor: color.BackgroundModal[theme],
						borderRadius: radiusMedium,
						overflow: "hidden",
						minWidth: 400,
						minHeight: 400,
						maxHeight: 0.9 * dim.height,
					},
				};
			case "ShortBlur":
				return {
					style: {
						backgroundColor: innerBlurBackgroundColor,
						overflow: "hidden",
						borderRadius: radiusMedium,
					},
				};
			case "Blur":
				return {
					style: {
						flex: 0,
					},
				};
			case "FullBlur":
				if (device === "mobile") {
					return {
						style: {
							flex: 1,
						},
					};
				}
				return {
					style: {
						backgroundColor: innerBlurBackgroundColor,
						borderRadius: radiusMedium,
						overflow: "hidden",
						minWidth: 400,
						minHeight: 400,
						maxHeight: 0.9 * dim.height,
					},
				};
			case "FullFull":
				return {
					style: {
						backgroundColor: color.BackgroundModal[theme],
						flex: 1,
					},
				};
			default:
				return enumNever(variant);
		}
	}, [variant, theme, device, dim]);

	const isFullScreen =
		(variant === "Tall" || variant === "Full" || variant === "FullBlur") && device === "mobile";
	const hasTouchable = !isFullScreen || variant === "FullBlur";
	const modelContextValue = useMemo<ModalContextType>(() => {
		return {
			name,
			closeSelf: () => close(name),
		};
	}, [close, name]);

	return (
		<SafeModal {...modalProps} testID="Modal" visible={isOpen}>
			<ModalContext.Provider value={modelContextValue}>
				{hasTouchable && (
					<TouchableWithoutFeedback
						disabled={!(config.allowClose ?? true)}
						onPress={() => {
							close(name);
						}}
						containerStyle={{
							position: "absolute",
							width: "100%",
							height: "100%",
							backgroundColor: color.BackgroundOverlay[theme],
						}}
					/>
				)}
				<ModalOpenDataManager />
				<View
					{...viewProps}
					style={[
						{
							maxWidth: dim.width,
							maxHeight: dim.height,
						},
						viewProps.style,
					]}
				>
					<View
						{...nestedViewProps}
						style={[
							{
								// Safety for a bug which caused modals to expand infinitely
								maxWidth: "100%",
								maxHeight: "100%",
							},
							nestedViewProps.style,
						]}
					>
						<config.component name={name} variant={variant} />
					</View>
				</View>
			</ModalContext.Provider>
		</SafeModal>
	);
};
export default memo(RootModalIndividual);
